import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Link from 'valuelink';
import { Input } from 'valuelink/lib/tags';

import { login, loginMagicLink, updateLoginState } from '../../actions/index';
import { BoldText } from '../../components/common/Text';
import { FadeInTransition } from '../../components/transitions/transitions';
import { PrimaryButton } from '../../components/common/Buttons';

import contactGraphic from '../../images/onboarding/contact.svg';
import { logEvent } from '../../utils/logEvent';
import { starLabsDashboardLogin } from '../../services/star-health';
import { showError } from '../../utils';

const OuterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 3rem 1.25rem;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-self: stretch;
  align-items: flex-start;
`;

const ScreenIcon = styled.img`
  max-width: 4.265rem;
`;

const TitleText = styled(BoldText)`
  font-size: ${() => `${1.25 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  align-self: flex-start;
  text-align: left;
  word-break: break-all;
`;

const InputContainer = styled.div`
  padding: 0.33rem 0;
  border-bottom: 1px solid black;
  display: flex;
  align-self: stretch;
  align-items: center;
  margin-top: 1.625rem;
  margin-bottom: 1.625rem;
`;

const CountryCodeText = styled(BoldText)`
  font-size: ${() => `${1 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  margin-right: 1.625rem;
`;

const StyledButtonText = styled(BoldText)`
  color: white;
  text-transform: uppercase;
`;

const StyledInputBox = styled(Input)`
  flex: 1;
`;

class OnboardingStart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneValue: '',
      loading: false,
      phonePeToken: '',
    };
  }

  componentDidMount = () => {
    const { history, loginState } = this.props;
    console.log(loginState);
    console.log(this.props.location.state);
    if (loginState === 'LOGGED_IN') {
      history.replace('/home');
    } else if (loginState === 'ONE_LAST_STEP') {
      history.push('/one-last-step');
    } else if (loginState === 'SPONSOR_EMAIL') {
      history.push('/corporate-onboard');
      return false;
    } else if (loginState === 'SELECT_USER') {
      history.push('/select-user');
    } else {
      // GA Tracking
      logEvent('Onboarding Phone Number Screen');
    }
  };

  componentWillUpdate = () => {
    // otp error then change loading back to false
    if (this.state.loading && this.props.appError.hasError) {
      this.setState({
        loading: false,
      });
    }
  };

  // submitPhoneNumber = () => {
  //   this.setState({
  //     loading: true,
  //   });
  //   console.log('Phone number submitted ', this.state.phoneValue);
  //   const { dispatch, history, location } = this.props;
  //   let encData;
  //   if (location.state) {
  //     encData = location.state.encData;
  //   }
  //   console.log(history);
  //   dispatch(
  //     login(
  //       this.state.phoneValue,
  //       91,
  //       encData,
  //       history,
  //       false,
  //       location.search,
  //     ),
  //   );
  // };

  dashboardLogin = () => {
    const { dispatch, history } = this.props;
    starLabsDashboardLogin(this.state.phoneValue)
      .then((res) => {
        console.log(res);
        if (res.magicCode) {
          dispatch(updateLoginState('LOGGED_OUT'));
          dispatch({
            type: 'UNSET_USER_INFO',
          });
          dispatch(loginMagicLink(res.magicCode, history));
        } else {
          window.location.assign(res.result);
        }
      })
      .catch((err) => {
        showError(err);
      });
  };

  render() {
    const { phonePeToken } = this.state;
    const phoneValueLink = Link.state(this, 'phoneValue');
    return (
      <FadeInTransition
        in={true}
        timeout={200}
        mountOnEnter
        unmountOnExit
        appear
      >
        <OuterContainer>
          <HeaderContainer>
            <ScreenIcon src={contactGraphic} alt="Phone" />
          </HeaderContainer>
          <TitleText>
            Enter your
            <br />
            Phone Number
          </TitleText>
          <TitleText>{phonePeToken}</TitleText>
          <InputContainer>
            <CountryCodeText>+91</CountryCodeText>
            <StyledInputBox
              type="tel"
              className="visit-input"
              maxLength={10}
              valueLink={phoneValueLink}
              placeholder="Enter Your Phone Number"
            />
          </InputContainer>
          <PrimaryButton
            onTap={this.dashboardLogin}
            disabled={this.state.phoneValue.length !== 10}
            loading={this.state.loading}
          >
            <StyledButtonText>next</StyledButtonText>
          </PrimaryButton>
        </OuterContainer>
      </FadeInTransition>
    );
  }
}

const mapStateToProps = (state) => ({
  appError: state.app.appError,
  loginState: state.app.loginState,
});

export default connect(mapStateToProps)(OnboardingStart);
